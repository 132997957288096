import React, {
  FC,
  useCallback,
  useState,
  useMemo,
  SyntheticEvent
} from "react";
import { CatalogNavigationProps } from "@RHCommerceDev/container-inline-header/types";
import {
  countries,
  measurements,
  languages
} from "@RHCommerceDev/resources/countries-config.json";
import {
  PAGE_BG_GREY,
  catalogLinksToEnable,
  CATALOG_NAV_CONCEPTS_ID,
  CATALOG_SALE_NAME,
  CATALOG_SALE_COLOR
} from "@RHCommerceDev/utils/constants";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useHistory } from "react-router";
import { useViewHeight } from "@RHCommerceDev/hooks/useViewHeight";
import buildPath from "@RHCommerceDev/utils/buildPath";
import classNames from "classnames";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";
import memoize from "@RHCommerceDev/utils/memoize";
import MobileCountrySelector from "@RHCommerceDev/container-rhr-header/MobileCountrySelector";
import RHCloseIcon from "@RHCommerceDev/icon-close";
import RHImage from "@RHCommerceDev/component-rh-image";
import RHLogo from "@RHCommerceDev/component-rh-logo";
import RHRArrowIcon from "@RHCommerceDev/icon-arrow-rhr";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";
import usePrevious from "@RHCommerceDev/hooks/usePrevious";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import {
  createStyles,
  Divider,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Slide,
  Theme,
  Typography,
  useTheme
} from "@material-ui/core";
import yn from "yn";
import useIsoRedirect from "hooks-use-isoredirect";
import useUserPreferences from "hooks/useUserPreferences";
import { processEnvServer } from "hooks/useSsrHooks";
import { useAppId } from "hooks-use-app-id";
import { isSSRToggledCheck } from "utils/ssrHelpers";
import { testPathForLocale } from "utils/intlUtils";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import { querySaleCatalogNavigation } from "graphql-client/queries/navigation";
import { useQuery } from "@apollo/client";
import useSite from "hooks/useSite";
import { useTypographyStyles } from "hooks/useTypographyStyles";
import useLocale from "hooks-use-locale/useLocale";
import { memoryStorage } from "utils/analytics/storage";
import { useCountry } from "hooks/useCountry";
import CountrySelector from "component-country-selector";
import { usePageContent } from "customProviders/LocationProvider";
import { CLOSE_HAMBURGER_MENU } from "resources/hamburger-nav.json";

export interface MobileMenuPanelProps {
  navigationItem: Maybe<NavigationElement>;
  rootCategory: string | null;
  rootCategoryID: string | null;
  activeAnalyticsAttribute: string | null;
  isAem?: boolean;
  onNavigateBack: () => void;
  onNavigateAway: (e: SyntheticEvent, navElement: NavigationElement) => void;
}

export interface MobileMenuProps {
  catalogNavigationProps?: CatalogNavigationProps;
  menuItems: Array<NavigationElement>;
  footerLinks?: Array<NavigationElement>;
  isAem?: boolean;
  onCloseMenu: () => void;
  sale?: boolean;
  isWORHMenuOpen?: boolean;
}

const useMenuPanelStyles = ({ viewHeight, isAem }) =>
  makeStyles((theme: Theme) =>
    createStyles({
      container: {
        height: `${100 * viewHeight}px`,
        overflow: "scroll"
      },
      categoryTitleContainer: {
        padding: theme.spacing(3),
        position: "relative"
      },
      arrow: {
        fontSize: 7
      },
      backArrow: {
        transform: "rotate(180deg)"
      },
      backIconContainer: {
        padding: `8px 0 8px 0`,
        top: theme.spacing(1.5),
        left: theme.spacing(3),
        "&:hover": {
          cursor: "pointer"
        }
      },
      categoryTitle: {
        lineHeight: `${theme.fontSizes.xl}px`
      },
      listItem: {
        padding: `${theme.spacing(2, 4)} !important`,
        "&:first-of-type": {
          paddingTop: 0
        },
        "&:last-of-type": {
          paddingBottom: 0
        }
      },
      uppercase: {
        "&.MuiTypography-h4": {
          fontFamily: "RHSans-Thin",
          textTransform: "uppercase"
        }
      }
    })
  );

const useMenuStyles = ({ viewHeight, isAem }) =>
  makeStyles((theme: Theme) =>
    createStyles({
      menuBody: {
        height: `${100 * viewHeight}px`
      },

      countrySelectorContainer: {
        padding: theme.spacing(2.5, 4),
        "&:hover": {
          cursor: "pointer"
        }
      },
      listItem: {
        padding: theme.spacing(2, 0),
        "&:last-of-type": {
          paddingBottom: 0
        },
        "&.MuiListItem-root": {
          padding: `${theme.spacing(2, 0)}!important`
        },

        "&.MuiListItem-gutters": {
          paddingLeft: "0px !important",
          paddingRight: "0px !important"
        }
      },
      uppercase: {
        "&.MuiTypography-h4": {
          fontFamily: "RHSans-Thin",
          textTransform: "uppercase"
        }
      }
    })
  );

export const MobileMenuPanel: FC<MobileMenuPanelProps> = ({
  navigationItem,
  rootCategory,
  rootCategoryID,
  activeAnalyticsAttribute,
  isAem,
  onNavigateBack,
  onNavigateAway
}) => {
  const viewHeight = useViewHeight();
  const classes = useMenuPanelStyles({ viewHeight, isAem })();
  const theme = useTheme();
  const brand = useBrand();
  const { setStorageValueWrapper } = useCookiesWithPermission();

  const [selectedChildItem, setSelectedChildItem] =
    useState<NavigationElement | null>(null);

  const previousNavItem = usePrevious(navigationItem);
  const navItem = useMemo(
    () => navigationItem || previousNavItem,
    [navigationItem, previousNavItem]
  );
  const isConceptsMenu = useMemo(
    () => navItem?.id === CATALOG_NAV_CONCEPTS_ID,
    [navItem]
  );

  const handleSelectChildItem = useCallback(
    (e: React.MouseEvent, navItem: NavigationElement): void => {
      e.stopPropagation();
      setSelectedChildItem(navItem);
    },
    []
  );

  return (
    <Slide
      direction="left"
      in={navigationItem !== null}
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.container}>
        <MobileMenuPanel
          navigationItem={selectedChildItem}
          rootCategory={rootCategory}
          activeAnalyticsAttribute={activeAnalyticsAttribute}
          onNavigateBack={() => setSelectedChildItem(null)}
          onNavigateAway={onNavigateAway}
          rootCategoryID={rootCategoryID}
        />
        {!selectedChildItem && (
          <div>
            <div
              className={classNames([
                classes.categoryTitleContainer,
                `flex items-center`
              ])}
            >
              <div
                onClick={onNavigateBack}
                data-testid="mobile-menu-panel-back"
                className={classNames([classes.backIconContainer])}
              >
                <RHRArrowIcon
                  strokeColor={theme.palette.common.black}
                  className={classNames(classes.arrow, classes.backArrow)}
                />
              </div>
              {isConceptsMenu ? (
                <RHLogo
                  brand={brand}
                  primary={false}
                  imgStyle={{ height: theme.spacing(2), width: "auto" }}
                />
              ) : (
                <div className={`w-full grid justify-center`}>
                  <Typography
                    className={classNames(
                      classes.uppercase,
                      classes.categoryTitle
                    )}
                  >
                    {navItem?.displayName}
                  </Typography>
                </div>
              )}
            </div>
            <Divider />
            <List className={"pt-8"} style={{ padding: theme.spacing(4, 0) }}>
              {navItem?.childCategories?.map(item => {
                const isSaleItem =
                  item.displayName?.trim()?.toLowerCase() === "sale";
                const isNavSaleItem =
                  navItem.displayName?.trim()?.toLowerCase() !== "sale";
                const isRootCategorySale =
                  rootCategory?.trim()?.toLowerCase() === "sale";
                const isSelected =
                  selectedChildItem?.displayName === item.displayName;
                const hasEndBr =
                  item?.displayName?.trim()?.endsWith("<br>") ?? false;
                return (
                  <ListItem
                    style={{
                      visibility:
                        isRootCategorySale &&
                        isNavSaleItem &&
                        isSaleItem &&
                        !item?.childCategories?.length
                          ? "hidden"
                          : "visible"
                    }}
                    className={classNames([
                      classes.listItem,
                      hasEndBr ? "mb-8" : ""
                    ])}
                    id={`mobile-menu-panel-navigation-${item.id}`}
                    data-testid={item.id}
                    data-navigation-account-item-id={item.id}
                    data-analytics-parentCat={navItem.displayName}
                    data-analytics-rootcat={rootCategory}
                    data-worh-type={
                      item?.childCategories?.length
                        ? null
                        : activeAnalyticsAttribute
                    }
                    data-analytics-targetURL={item.targetUrl}
                    key={`navigation-${item.id}`}
                    onClick={e => {
                      !(
                        item?.childCategories &&
                        item?.childCategories?.length > 0
                      )
                        ? onNavigateAway(e, item)
                        : handleSelectChildItem(e, item);

                      if (
                        !processEnvServer &&
                        !item?.childCategories &&
                        item?.targetUrl
                      ) {
                        // Setting Up Catalog Object To Track Navigation Path
                        localStorage.removeItem("analytics-navCatalog");
                        localStorage.removeItem("fromNav");
                        const navCatalog = {
                          topCat: {
                            id: rootCategoryID,
                            title: rootCategory
                          },
                          parentCat: {
                            id: navItem?.id,
                            title: navItem?.displayName
                          },
                          cat: {
                            id: item?.id,
                            title: item?.displayName
                          }
                        };
                        setStorageValueWrapper({
                          storageKey: "fromNav",
                          value: "true"
                        });
                        setStorageValueWrapper({
                          storageKey: "analytics-navCatalog",
                          value: JSON.stringify(navCatalog)
                        });
                        memoryStorage.setItem("fromNav", true);
                      }
                    }}
                    aria-label={item.displayName}
                    aria-expanded={isSelected}
                  >
                    <div data-testid={item.id} className={`flex w-full`}>
                      <div className={`w-full`}>
                        <Typography
                          variant="h4"
                          className={classNames(
                            classes.uppercase,
                            "max-w-[90%]",
                            {
                              "text-[#CA2022]": isSaleItem
                            }
                          )}
                          dangerouslySetInnerHTML={{
                            __html:
                              item?.displayName?.replace(/<br>/g, "") ?? ""
                          }}
                        />
                      </div>
                      {!!item.childCategories?.length && (
                        <div>
                          <RHRArrowIcon
                            strokeColor={theme.palette.common.black}
                            className={classes.arrow}
                            onClick={e => handleSelectChildItem(e, item)}
                          />
                        </div>
                      )}
                    </div>
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
      </div>
    </Slide>
  );
};

export const MobileMenu: FC<MobileMenuProps> = ({
  catalogNavigationProps,
  menuItems,
  footerLinks,
  isAem,
  sale = false,
  onCloseMenu,
  isWORHMenuOpen
}) => {
  // const nextgenPg =" useIsoCookies(["nextgenpg"])?.nextgenpg";
  //defaulting it to NG
  const nextgenPg = "true";
  const viewHeight = useViewHeight();
  const classes = useMenuStyles({ viewHeight, isAem })();
  const typographyStyles = useTypographyStyles({
    keys: ["uppercaseText"]
  });

  const env = useEnv();
  const theme = useTheme();
  const history = useHistory();
  const brand = useBrand();
  const { pageContent } = usePageContent();
  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const FEATURE_SUPPRESS_UK = yn(env.FEATURE_SUPPRESS_UK);
  const FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE = yn(
    env.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
  );
  const { isConcierge } = useAppId();
  const isSSRToggled = isSSRToggledCheck();

  const prefix = useLocalization();
  const { previousState } = useUserPreferences();
  const selectedMeasurement = measurements.find(
    measurement => measurement?.value === previousState?.measurement
  );
  const selectedLanguage = languages.find(
    language => language?.value === previousState?.language
  );

  const [isCountrySelectorVisible, setIsCountrySelectorVisible] =
    useState<boolean>(false);
  const [activeShippingSelector, setActiveShippingSelector] = useState(null);
  const [selectedWORHRootCategory, setSelectedWORHRootCategory] = useState<
    string | null
  >(null);
  const [rootCategoryID, setRootCategoryID] = useState<string | null>(null);
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<NavigationElement | null>(null);
  const [selectedNavItem, setSelectedNavItem] = useState<string | null>(null);
  const [activeAnalyticsAttribute, setActiveAnalyticsAttribute] = useState<
    string | null
  >(null);
  const CLOSE_HAMBURGER_LABEL =
    pageContent?.hamburgerNav?.CLOSE_HAMBURGER_MENU ?? CLOSE_HAMBURGER_MENU;

  const imgPublicUrl = (env.PUBLIC_URL || "").replace(new RegExp(/\/?$/gm), "");

  const catalogNavigationChildren =
    catalogNavigationProps?.catalogNavigation?.childCategories;

  const selectedCountry = useMemo(() => getCountryFromUrl() ?? "US", []);

  const countryDisplayName = useMemo(
    () =>
      Object.values(countries).find(
        country => country.value === selectedCountry
      )?.label ?? "United States($)",
    [selectedCountry]
  );

  const handleToggleCountrySelector = useCallback(
    (type = null) => {
      setIsCountrySelectorVisible(!isCountrySelectorVisible);
      setActiveShippingSelector(type);
    },
    [isCountrySelectorVisible]
  );

  const handleUnselectNavItem = useCallback(() => {
    setSelectedWORHRootCategory(null);
    setSelectedMenuItem(null);
  }, []);

  const handleNavigationClick = useCallback(
    (e: SyntheticEvent, navElement: NavigationElement) => {
      e.preventDefault?.();
      setSelectedNavItem(navElement?.displayName);
      const { linkType, targetUrl } = navElement;

      if (linkType === "none") {
        return useIsoRedirect(targetUrl, "windowOpen");
      }

      if (linkType === "anchor") {
        return useIsoRedirect(`https://${targetUrl}`, "windowOpen", {
          windowOpenType: "_blank"
        });
      }

      if (targetUrl?.trim().indexOf("http") === 0) {
        window.location.assign(targetUrl);
      } else {
        const hasLocale = testPathForLocale(targetUrl);
        const isSiteMapOrEmailPage =
          targetUrl?.includes("sitemap.jsp") ||
          targetUrl?.includes("email-signup.jsp");
        const withPrefix = !!hasLocale || !!isSiteMapOrEmailPage ? "" : prefix;
        if (isSSRToggled) {
          window.location.assign(buildPath(`${withPrefix}${targetUrl}`));
        } else {
          history.push(buildPath(`${withPrefix}${targetUrl}`));
        }
      }

      onCloseMenu();
    },
    [history, prefix, onCloseMenu]
  );

  const handleSelectNavItem = useCallback(
    (
      navItem: NavigationElement,
      rootCategory?: string,
      target?: HTMLElement
    ) => {
      const id = (target?.parentNode as HTMLElement)?.id;
      const { targetUrl, linkType } = navItem;
      let category;
      if (!navItem?.childCategories?.length) {
        if (!processEnvServer) {
          document?.body?.dispatchEvent(
            new CustomEvent("cta_click", {
              detail: {
                item: {
                  isHeaderNavigation: true,
                  topCat: navItem.displayName,
                  parentCat: navItem.displayName,
                  cat: navItem.targetUrl,
                  catURL: navItem.targetUrl,
                  class: target?.className,
                  id: target?.id || id
                }
              }
            })
          );
        }
        if (linkType === "none") {
          window.open(`${targetUrl}`, "_blank");
        } else {
          window.open(`${prefix}${targetUrl}`, "_self");
        }
      } else {
        if (targetUrl?.indexOf(".jsp") === -1) {
          category = targetUrl;
        } else {
          category = targetUrl?.substring(0, targetUrl?.indexOf(".jsp") + 4);
          if (category === "/content/category.jsp") {
            category = targetUrl;
          }
        }
        if (catalogLinksToEnable.includes(category)) {
          return handleNavigationClick({} as React.SyntheticEvent, navItem);
        }
        if (rootCategory) {
          setSelectedWORHRootCategory(rootCategory);
          setRootCategoryID(navItem?.id);
        }
        setSelectedMenuItem(navItem);
      }
    },
    [handleNavigationClick, prefix]
  );

  const handleSelectCatalogItem = useCallback(
    (event: React.MouseEvent, item: NavigationElement) => {
      const target = event.target as HTMLElement;
      item.id === CATALOG_NAV_CONCEPTS_ID
        ? setActiveAnalyticsAttribute("rhr-uber-cat")
        : setActiveAnalyticsAttribute("rhr-sub-cat");
      handleSelectNavItem(item, item.displayName, target);
    },
    [handleSelectNavItem]
  );

  const handleSelectWoRHItem = useCallback(
    (
      event: React.MouseEvent,
      item: NavigationElement,
      rootCategory: string
    ) => {
      const target = event.target as HTMLElement;
      setActiveAnalyticsAttribute("worh-icon");
      handleSelectNavItem(item, rootCategory, target);
    },
    [handleSelectNavItem]
  );

  const handleSelectFooterItem = useCallback(
    (event: React.MouseEvent, item: NavigationElement) => {
      const target = event.target as HTMLElement;
      setActiveAnalyticsAttribute("worh-nav-footer");
      handleSelectNavItem(item, item.displayName, target);
    },
    [handleSelectNavItem]
  );

  const siteId = useSite();
  const locale = useLocale();
  const countryCode = useCountry();

  const {
    data: { saleCatalogNavigation: saleDataCatalogNavigation } = {} as Query
  } = useQuery<Query>(querySaleCatalogNavigation, {
    variables: {
      filter: "sale",
      siteId,
      locale,
      countryCode,
      nextgenPg: nextgenPg || "false"
    }
  });

  return (
    <div className={"relative"}>
      <IconButton
        disableRipple
        className={`!absolute ${
          isAem ? "-top-[88px]" : "top-[8px]"
        } left-[304px]`}
        onClick={onCloseMenu}
        data-testid="dialog-title-close-button"
        aria-label={CLOSE_HAMBURGER_LABEL}
        aria-expanded={isWORHMenuOpen}
      >
        <RHCloseIcon className={"text-[#fff] text-[20px]"} />
      </IconButton>

      <div
        className={`${classes.menuBody} overflow-x-hidden overflow-y-scroll`}
      >
        <MobileMenuPanel
          navigationItem={selectedMenuItem}
          rootCategory={selectedWORHRootCategory}
          rootCategoryID={rootCategoryID}
          activeAnalyticsAttribute={activeAnalyticsAttribute}
          onNavigateBack={handleUnselectNavItem}
          onNavigateAway={handleNavigationClick}
        />
        {sale && (
          <>
            <div className={`flex justify-center items-center`}>
              <Typography
                className={classNames(
                  classes.uppercase,
                  "!mt-[32px] !mx-0 !mb-[16px]"
                )}
              >
                {catalogNavigationProps?.catalogNavigation?.displayName}
              </Typography>
            </div>
            <Divider />
          </>
        )}

        {!sale && (
          <MobileCountrySelector
            isVisible={isCountrySelectorVisible}
            onClose={handleToggleCountrySelector}
            activeShippingSelector={activeShippingSelector}
          />
        )}

        {!isCountrySelectorVisible && selectedMenuItem === null && (
          <>
            <List className={"!pt-[16px] !px-[32px] !pb-0"} component={"ul"}>
              {catalogNavigationChildren?.map(item => {
                if (
                  item.displayName?.toLowerCase() ===
                  saleDataCatalogNavigation?.displayName?.toLowerCase()
                ) {
                  item.childCategories =
                    saleDataCatalogNavigation?.childCategories;
                  item.targetUrl = "/catalog/category/category.jsp";
                }
                const isHiddenConciergeLink =
                  isConcierge &&
                  (item?.targetUrl?.includes("wish-list.jsp") ||
                    item?.displayName?.toLowerCase() === "registry");
                const isSelected =
                  selectedMenuItem?.displayName === item.displayName;
                return !isHiddenConciergeLink ? (
                  <ListItem
                    component={"li"}
                    className={`${classes.listItem} !py-[16px] !px-[0px]`}
                    id={`mobile-menu-navigation-${item.id}`}
                    data-testid={item.id}
                    data-navigation-account-item-id={item.id}
                    key={`navigation-${item.id}`}
                    button
                    onClick={e => handleSelectCatalogItem(e, item)}
                    aria-label={item.displayName?.replace(/<br>/g, "") ?? ""}
                    aria-expanded={isSelected}
                  >
                    <div
                      data-testid={item.id}
                      className={`w-full grid grid-cols-2 h-full items-center`}
                    >
                      <div>
                        {item.id === CATALOG_NAV_CONCEPTS_ID ? (
                          <RHLogo
                            brand={brand}
                            primary={false}
                            imgStyle={{
                              height: theme.spacing(2),
                              width: "auto"
                            }}
                          />
                        ) : (
                          <Typography
                            variant="h4"
                            className={classes.uppercase}
                            style={
                              item?.displayName?.toUpperCase() ===
                              CATALOG_SALE_NAME?.toUpperCase()
                                ? { color: CATALOG_SALE_COLOR }
                                : undefined
                            }
                            dangerouslySetInnerHTML={{
                              __html:
                                item.displayName?.replace(/<br>/g, "") ?? ""
                            }}
                          />
                        )}
                      </div>
                      {!!item.childCategories?.length && (
                        <div className={`place-self-end`}>
                          <RHRArrowIcon
                            strokeColor={theme.palette.common.black}
                            className={"!text-[7px]"}
                          />
                        </div>
                      )}
                    </div>
                  </ListItem>
                ) : null;
              })}
            </List>
            {!sale && !isCountrySelectorVisible && selectedMenuItem === null && (
              <div className={"mb-[56px]"}>
                <Divider className={"mt-[32px]"} />
                {FEATURE_INTERNATIONAL ? (
                  <>
                    {FEATURE_SUPPRESS_UK ? null : (
                      <div
                        className={"py-[20px] px-[32px] grid justify-between"}
                      >
                        <CountrySelector />
                      </div>
                    )}
                  </>
                ) : null}
                <Divider />
              </div>
            )}
            {!sale && (
              <img
                className="!w-40 !py-0 !pt-0 !pl-8"
                src={`${imgPublicUrl}/img/WORH_Logo_Inline.svg`}
                alt={`World of RH Logo`}
              />
            )}

            {menuItems.map(category => (
              <div key={category.id} className={"p-[32px]"}>
                {!!category.navImage && (
                  <a
                    data-worh-type="worh-icon"
                    data-testid={`menu-item-nav-image-${category.id}`}
                    href={category.targetUrl}
                    className={"no-underline"}
                    onClick={e => handleNavigationClick(e, category)}
                  >
                    <RHImage src={category.navImage} loadingBehavior="eager" />
                  </a>
                )}
                <a
                  data-worh-type="worh-icon123"
                  href={category.targetUrl}
                  className={"no-underline"}
                  onClick={e => handleNavigationClick(e, category)}
                >
                  <Typography
                    variant="subtitle1"
                    className={classNames([
                      "!mt-[32px] !mx-0 !mb-[16px]",
                      classes.uppercase,
                      typographyStyles.uppercaseText
                    ])}
                  >
                    {category.displayName}
                  </Typography>
                </a>
                <List disablePadding>
                  {category.childCategories?.map(navItem => {
                    const isSelected = selectedNavItem === navItem.displayName;
                    return (
                      <ListItem
                        key={navItem.id}
                        className={`${classes.listItem} !py-[16px] !px-[0px]`}
                        aria-label={navItem?.displayName?.replace(/<br>/g, "")}
                        aria-expanded={isSelected}
                      >
                        <div className={`flex items-center gap-1`}>
                          <div>
                            <a
                              data-worh-type="worh-icon"
                              data-analytics-rootCat={category.displayName}
                              href={navItem.targetUrl}
                              className={"no-underline"}
                              onClick={e => handleNavigationClick(e, navItem)}
                            >
                              <Typography
                                variant="h4"
                                className={classNames(
                                  classes.uppercase,
                                  typographyStyles.uppercaseText
                                )}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    navItem?.displayName?.replace(
                                      /<br>/g,
                                      ""
                                    ) ?? ""
                                }}
                              />
                            </a>
                          </div>
                          {!!navItem.childCategories && (
                            <div>
                              <RHRArrowIcon
                                strokeColor={theme.palette.common.black}
                                data-testid={`menu-item-child-icon-${navItem.id}`}
                                className={"!text-[7px]"}
                                onClick={e =>
                                  handleSelectWoRHItem(
                                    e,
                                    navItem,
                                    category?.displayName ?? ""
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            ))}
          </>
        )}

        {!!footerLinks &&
          selectedMenuItem === null &&
          !isCountrySelectorVisible && (
            <List
              className={`!pt-[40px] !px-[32px] !pb-[48px] ${
                theme?.palette?.background?.default === PAGE_BG_GREY
                  ? "bg-[#f9f7f4]"
                  : "bg-[#F5F5F5]"
              }`}
            >
              {footerLinks.map(footerLink => (
                <ListItem
                  key={footerLink.id}
                  disableGutters
                  className={`${"mb-3 p-0 last:mb-0"} mb-[24px] p-0`}
                >
                  <div className={`flex w-full items-center`}>
                    <div
                      onClick={e =>
                        !!footerLink.childCategories &&
                        handleSelectFooterItem(e, footerLink)
                      }
                      className={`w-full`}
                    >
                      <a
                        data-worh-type="worh-footer"
                        className={"no-underline"}
                        onClick={e =>
                          !footerLink.childCategories &&
                          handleNavigationClick(e, footerLink)
                        }
                      >
                        <Typography
                          variant="caption"
                          className={classes.uppercase}
                          dangerouslySetInnerHTML={{
                            __html: footerLink.displayName.replace(/<br>/g, "")
                          }}
                        />
                      </a>
                    </div>
                    {!!footerLink.childCategories && (
                      <div>
                        <RHRArrowIcon
                          data-testid={`footer-item-child-icon-${footerLink.id}`}
                          strokeColor={theme.palette.common.black}
                          className={"!text-[7px]"}
                          onClick={e => handleSelectFooterItem(e, footerLink)}
                        />
                      </div>
                    )}
                  </div>
                </ListItem>
              ))}
            </List>
          )}
      </div>
    </div>
  );
};

export default memoize(MobileMenu);
